exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-category-wc-products-categories-slug-tsx": () => import("./../../../src/pages/category/{wcProductsCategories.slug}.tsx" /* webpackChunkName: "component---src-pages-category-wc-products-categories-slug-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-product-wc-products-slug-tsx": () => import("./../../../src/pages/product/{wcProducts.slug}.tsx" /* webpackChunkName: "component---src-pages-product-wc-products-slug-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-reset-tsx": () => import("./../../../src/pages/reset.tsx" /* webpackChunkName: "component---src-pages-reset-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-tag-wc-products-tags-slug-tsx": () => import("./../../../src/pages/tag/{wcProductsTags.slug}.tsx" /* webpackChunkName: "component---src-pages-tag-wc-products-tags-slug-tsx" */),
  "component---src-pages-view-[id]-tsx": () => import("./../../../src/pages/view/[id].tsx" /* webpackChunkName: "component---src-pages-view-[id]-tsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */),
  "component---src-pages-youngin-tsx": () => import("./../../../src/pages/youngin.tsx" /* webpackChunkName: "component---src-pages-youngin-tsx" */)
}

